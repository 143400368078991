import Vue from 'vue';
import VueGtag from 'vue-gtag';

export default ((ctx) => {
  Vue.use(VueGtag, {
    appName: 'Sckool',
    pageTrackerScreenviewEnabled: true,
    config: {
      id: process.env.GOOGLE_ANALYTICS_ID,
    },
    disabled: () => {
      const getGDPR = window.localStorage.getItem('GDPR:accepted');
      if (getGDPR === 'no') {
        return true;
      }

      return !(getGDPR === 'yes');
    },
  }, ctx.app.router);
});
