import VueScript2 from 'vue-script2';

export default () => {
  VueScript2.load('//apis.google.com/js/api.js').then(() => {
    const connect = (() => {
      window.gapi.client.init({
        apiKey: process.env.GOOGLE_API_KEY,
        clientId: process.env.GOOGLE_CLIENT_ID,
        scope: 'profile',
      });
    });
    window.gapi.load('client', () => {
      connect();
    });
  });
};
