const middleware = {}

middleware['adminToken'] = require('../middleware/adminToken.js')
middleware['adminToken'] = middleware['adminToken'].default || middleware['adminToken']

middleware['login-check'] = require('../middleware/login-check.js')
middleware['login-check'] = middleware['login-check'].default || middleware['login-check']

middleware['tokenCheck'] = require('../middleware/tokenCheck.js')
middleware['tokenCheck'] = middleware['tokenCheck'].default || middleware['tokenCheck']

export default middleware
