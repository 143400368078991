import { datadogRum } from '@datadog/browser-rum';

export default ({ $config: { ddApplicationId, ddApplicationToken, ddVersion, ddEnv, ddService } }) => {
  datadogRum.init({
    applicationId: ddApplicationId,
    clientToken: ddApplicationToken,
    site: 'datadoghq.com',
    service: ddService,
    version: ddVersion,
    env: ddEnv,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      { match: process.env.SERVER_HOST, propagatorTypes: ['tracecontext'] },
      { match: 'http://api.sckool.kr', propagatorTypes: ['tracecontext'] },
      { match: 'http://staging-api.sckool.kr', propagatorTypes: ['tracecontext'] },
    ],
  });
  datadogRum.startSessionReplayRecording();
};
