export const MainBasicButton = () => import('../../components/main/basic/Button.vue' /* webpackChunkName: "components/main-basic-button" */).then(c => wrapFunctional(c.default || c))
export const MainBasicCheckbox = () => import('../../components/main/basic/Checkbox.vue' /* webpackChunkName: "components/main-basic-checkbox" */).then(c => wrapFunctional(c.default || c))
export const MainBasicDialog = () => import('../../components/main/basic/Dialog.vue' /* webpackChunkName: "components/main-basic-dialog" */).then(c => wrapFunctional(c.default || c))
export const MainBasicInput = () => import('../../components/main/basic/Input.vue' /* webpackChunkName: "components/main-basic-input" */).then(c => wrapFunctional(c.default || c))
export const MainBasicKeyboardImage = () => import('../../components/main/basic/KeyboardImage.vue' /* webpackChunkName: "components/main-basic-keyboard-image" */).then(c => wrapFunctional(c.default || c))
export const MainBasicRadio = () => import('../../components/main/basic/Radio.vue' /* webpackChunkName: "components/main-basic-radio" */).then(c => wrapFunctional(c.default || c))
export const MainBasicRadioGroup = () => import('../../components/main/basic/RadioGroup.vue' /* webpackChunkName: "components/main-basic-radio-group" */).then(c => wrapFunctional(c.default || c))
export const MainBasicSelect = () => import('../../components/main/basic/Select.vue' /* webpackChunkName: "components/main-basic-select" */).then(c => wrapFunctional(c.default || c))
export const MainBasicVirtualKeyboard = () => import('../../components/main/basic/VirtualKeyboard.vue' /* webpackChunkName: "components/main-basic-virtual-keyboard" */).then(c => wrapFunctional(c.default || c))
export const MainNativeBackButton = () => import('../../components/main/native/BackButton.vue' /* webpackChunkName: "components/main-native-back-button" */).then(c => wrapFunctional(c.default || c))
export const MainNativeBarChart = () => import('../../components/main/native/BarChart.vue' /* webpackChunkName: "components/main-native-bar-chart" */).then(c => wrapFunctional(c.default || c))
export const MainNativeChancesInformation = () => import('../../components/main/native/ChancesInformation.vue' /* webpackChunkName: "components/main-native-chances-information" */).then(c => wrapFunctional(c.default || c))
export const MainNativeChangePasswordForm = () => import('../../components/main/native/ChangePasswordForm.vue' /* webpackChunkName: "components/main-native-change-password-form" */).then(c => wrapFunctional(c.default || c))
export const MainNativeClassStatusBadge = () => import('../../components/main/native/ClassStatusBadge.vue' /* webpackChunkName: "components/main-native-class-status-badge" */).then(c => wrapFunctional(c.default || c))
export const MainNativeCommentInput = () => import('../../components/main/native/CommentInput.vue' /* webpackChunkName: "components/main-native-comment-input" */).then(c => wrapFunctional(c.default || c))
export const MainNativeCommentTextarea = () => import('../../components/main/native/CommentTextarea.vue' /* webpackChunkName: "components/main-native-comment-textarea" */).then(c => wrapFunctional(c.default || c))
export const MainNativeCookie = () => import('../../components/main/native/Cookie.vue' /* webpackChunkName: "components/main-native-cookie" */).then(c => wrapFunctional(c.default || c))
export const MainNativeEmptyContent = () => import('../../components/main/native/EmptyContent.vue' /* webpackChunkName: "components/main-native-empty-content" */).then(c => wrapFunctional(c.default || c))
export const MainNativeFooter = () => import('../../components/main/native/Footer.vue' /* webpackChunkName: "components/main-native-footer" */).then(c => wrapFunctional(c.default || c))
export const MainNativeGoogleButton = () => import('../../components/main/native/GoogleButton.vue' /* webpackChunkName: "components/main-native-google-button" */).then(c => wrapFunctional(c.default || c))
export const MainNativeHeader = () => import('../../components/main/native/Header.vue' /* webpackChunkName: "components/main-native-header" */).then(c => wrapFunctional(c.default || c))
export const MainNativeIconLabel = () => import('../../components/main/native/IconLabel.vue' /* webpackChunkName: "components/main-native-icon-label" */).then(c => wrapFunctional(c.default || c))
export const MainNativeInfiniteLoading = () => import('../../components/main/native/InfiniteLoading.vue' /* webpackChunkName: "components/main-native-infinite-loading" */).then(c => wrapFunctional(c.default || c))
export const MainNativeLabelInput = () => import('../../components/main/native/LabelInput.vue' /* webpackChunkName: "components/main-native-label-input" */).then(c => wrapFunctional(c.default || c))
export const MainNativeLabelSelect = () => import('../../components/main/native/LabelSelect.vue' /* webpackChunkName: "components/main-native-label-select" */).then(c => wrapFunctional(c.default || c))
export const MainNativeNewsletter = () => import('../../components/main/native/Newsletter.vue' /* webpackChunkName: "components/main-native-newsletter" */).then(c => wrapFunctional(c.default || c))
export const MainNativeProfileButton = () => import('../../components/main/native/ProfileButton.vue' /* webpackChunkName: "components/main-native-profile-button" */).then(c => wrapFunctional(c.default || c))
export const MainNativeProfileImageUploader = () => import('../../components/main/native/ProfileImageUploader.vue' /* webpackChunkName: "components/main-native-profile-image-uploader" */).then(c => wrapFunctional(c.default || c))
export const MainNativeReadOnlySlider = () => import('../../components/main/native/ReadOnlySlider.vue' /* webpackChunkName: "components/main-native-read-only-slider" */).then(c => wrapFunctional(c.default || c))
export const MainNativeRecordButton = () => import('../../components/main/native/RecordButton.vue' /* webpackChunkName: "components/main-native-record-button" */).then(c => wrapFunctional(c.default || c))
export const MainNativeReplyInput = () => import('../../components/main/native/ReplyInput.vue' /* webpackChunkName: "components/main-native-reply-input" */).then(c => wrapFunctional(c.default || c))
export const MainNativeSpeakAloneRecordButton = () => import('../../components/main/native/SpeakAloneRecordButton.vue' /* webpackChunkName: "components/main-native-speak-alone-record-button" */).then(c => wrapFunctional(c.default || c))
export const MainNativeYoutube = () => import('../../components/main/native/Youtube.vue' /* webpackChunkName: "components/main-native-youtube" */).then(c => wrapFunctional(c.default || c))
export const MainNativeHeaderDropdown = () => import('../../components/main/native/header/Dropdown.vue' /* webpackChunkName: "components/main-native-header-dropdown" */).then(c => wrapFunctional(c.default || c))
export const MainNativeHeaderGuestNavigation = () => import('../../components/main/native/header/GuestNavigation.vue' /* webpackChunkName: "components/main-native-header-guest-navigation" */).then(c => wrapFunctional(c.default || c))
export const MainNativeHeaderNavigation = () => import('../../components/main/native/header/Navigation.vue' /* webpackChunkName: "components/main-native-header-navigation" */).then(c => wrapFunctional(c.default || c))
export const MainNativeHeaderUserInformation = () => import('../../components/main/native/header/UserInformation.vue' /* webpackChunkName: "components/main-native-header-user-information" */).then(c => wrapFunctional(c.default || c))
export const AdminButton = () => import('../../components/admin/Button.vue' /* webpackChunkName: "components/admin-button" */).then(c => wrapFunctional(c.default || c))
export const AdminCheckbox = () => import('../../components/admin/Checkbox.vue' /* webpackChunkName: "components/admin-checkbox" */).then(c => wrapFunctional(c.default || c))
export const AdminDialog = () => import('../../components/admin/Dialog.vue' /* webpackChunkName: "components/admin-dialog" */).then(c => wrapFunctional(c.default || c))
export const AdminInfiniteLoading = () => import('../../components/admin/InfiniteLoading.vue' /* webpackChunkName: "components/admin-infinite-loading" */).then(c => wrapFunctional(c.default || c))
export const AdminInput = () => import('../../components/admin/Input.vue' /* webpackChunkName: "components/admin-input" */).then(c => wrapFunctional(c.default || c))
export const AdminSelect = () => import('../../components/admin/Select.vue' /* webpackChunkName: "components/admin-select" */).then(c => wrapFunctional(c.default || c))
export const AdminTextarea = () => import('../../components/admin/Textarea.vue' /* webpackChunkName: "components/admin-textarea" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
