export default async ({ app, store, route: { path } }) => {
  let tokenKey = 'token';
  if (path.indexOf('/admin') === 0) tokenKey = 'admin-token';
  try {
    const token = app.$cookies.get(tokenKey);
    if (token) {
      if (tokenKey === 'admin-token') await store.dispatch('auth/adminLogin');
      else await store.dispatch('auth/userLogin');
    }
  } catch (e) {
    app.$cookies.remove(tokenKey);
    console.error(e);
  }
};
