import Vue from 'vue';

export default () => {
  Vue.prototype.$alert = async (message) => {
    const { $dialog } = Vue.prototype;
    if (message) {
      await $dialog.alert({
        message,
        confirmButtonText: '확인 Okay',
        confirmButtonColor: '#369AFE',
      });
    }
  };
};
