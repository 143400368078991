import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c326f1ce = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _0fe28a33 = () => interopDefault(import('../pages/admin/index/classes/index.vue' /* webpackChunkName: "pages/admin/index/classes/index" */))
const _727b4fdd = () => interopDefault(import('../pages/admin/index/comments/index.vue' /* webpackChunkName: "pages/admin/index/comments/index" */))
const _3ec6d75e = () => interopDefault(import('../pages/admin/index/lectures/index.vue' /* webpackChunkName: "pages/admin/index/lectures/index" */))
const _5a56c491 = () => interopDefault(import('../pages/admin/index/level/index.vue' /* webpackChunkName: "pages/admin/index/level/index" */))
const _0f1ac6b1 = () => interopDefault(import('../pages/admin/index/purchase-history/index.vue' /* webpackChunkName: "pages/admin/index/purchase-history/index" */))
const _5b69ee1c = () => interopDefault(import('../pages/admin/index/resources/index.vue' /* webpackChunkName: "pages/admin/index/resources/index" */))
const _7cc16215 = () => interopDefault(import('../pages/admin/index/users/index.vue' /* webpackChunkName: "pages/admin/index/users/index" */))
const _01292b57 = () => interopDefault(import('../pages/admin/index/users-admin/index.vue' /* webpackChunkName: "pages/admin/index/users-admin/index" */))
const _0faa6114 = () => interopDefault(import('../pages/admin/index/voice-test/index.vue' /* webpackChunkName: "pages/admin/index/voice-test/index" */))
const _142306eb = () => interopDefault(import('../pages/admin/index/classes/create.vue' /* webpackChunkName: "pages/admin/index/classes/create" */))
const _7c6f41c0 = () => interopDefault(import('../pages/admin/index/lectures/create.vue' /* webpackChunkName: "pages/admin/index/lectures/create" */))
const _c26b3fe8 = () => interopDefault(import('../pages/admin/index/resources/create.vue' /* webpackChunkName: "pages/admin/index/resources/create" */))
const _16fd4ba3 = () => interopDefault(import('../pages/admin/index/classes/_classId.vue' /* webpackChunkName: "pages/admin/index/classes/_classId" */))
const _5b4a9ae1 = () => interopDefault(import('../pages/admin/index/lectures/_lectureId/index.vue' /* webpackChunkName: "pages/admin/index/lectures/_lectureId/index" */))
const _af8212f0 = () => interopDefault(import('../pages/admin/index/resources/_resourceId.vue' /* webpackChunkName: "pages/admin/index/resources/_resourceId" */))
const _06c14751 = () => interopDefault(import('../pages/admin/index/lectures/_lectureId/lessons/create.vue' /* webpackChunkName: "pages/admin/index/lectures/_lectureId/lessons/create" */))
const _7d7926e6 = () => interopDefault(import('../pages/admin/index/lectures/_lectureId/lessons/_lessonId.vue' /* webpackChunkName: "pages/admin/index/lectures/_lectureId/lessons/_lessonId" */))
const _6477669a = () => interopDefault(import('../pages/admin/login/index.vue' /* webpackChunkName: "pages/admin/login/index" */))
const _5652ce94 = () => interopDefault(import('../pages/admin/sample/index.vue' /* webpackChunkName: "pages/admin/sample/index" */))
const _61eb55ce = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e1023648 = () => interopDefault(import('../pages/index/index.vue' /* webpackChunkName: "pages/index/index" */))
const _5f7ad8a6 = () => interopDefault(import('../pages/index/contact/index.vue' /* webpackChunkName: "pages/index/contact/index" */))
const _183c0c36 = () => interopDefault(import('../pages/index/login/index.vue' /* webpackChunkName: "pages/index/login/index" */))
const _3f28842e = () => interopDefault(import('../pages/index/my-classes/index.vue' /* webpackChunkName: "pages/index/my-classes/index" */))
const _586a34c6 = () => interopDefault(import('../pages/index/my-page/index.vue' /* webpackChunkName: "pages/index/my-page/index" */))
const _05b237d3 = () => interopDefault(import('../pages/index/pricing/index.vue' /* webpackChunkName: "pages/index/pricing/index" */))
const _4ce6521c = () => interopDefault(import('../pages/index/resources/index.vue' /* webpackChunkName: "pages/index/resources/index" */))
const _2b0bd381 = () => interopDefault(import('../pages/index/signup/index.vue' /* webpackChunkName: "pages/index/signup/index" */))
const _7c615c4b = () => interopDefault(import('../pages/index/my-classes/_classId/index.vue' /* webpackChunkName: "pages/index/my-classes/_classId/index" */))
const _832376f0 = () => interopDefault(import('../pages/index/resources/_resourceId.vue' /* webpackChunkName: "pages/index/resources/_resourceId" */))
const _c9c80f2c = () => interopDefault(import('../pages/index/statistics/_classId.vue' /* webpackChunkName: "pages/index/statistics/_classId" */))
const _52a9c3f0 = () => interopDefault(import('../pages/index/video-assignment/_id.vue' /* webpackChunkName: "pages/index/video-assignment/_id" */))
const _01debecf = () => interopDefault(import('../pages/index/my-classes/_classId/lectures/index.vue' /* webpackChunkName: "pages/index/my-classes/_classId/lectures/index" */))
const _483907ed = () => interopDefault(import('../pages/index/speak-alone/_lectureId/result.vue' /* webpackChunkName: "pages/index/speak-alone/_lectureId/result" */))
const _1b882ec2 = () => interopDefault(import('../pages/index/speak-alone/_lectureId/solve.vue' /* webpackChunkName: "pages/index/speak-alone/_lectureId/solve" */))
const _cfe803d4 = () => interopDefault(import('../pages/index/speak-test/_lectureId/result.vue' /* webpackChunkName: "pages/index/speak-test/_lectureId/result" */))
const _26e5ed54 = () => interopDefault(import('../pages/index/speak-test/_lectureId/solve.vue' /* webpackChunkName: "pages/index/speak-test/_lectureId/solve" */))
const _457652d0 = () => interopDefault(import('../pages/index/my-classes/_classId/lectures/_lectureId/index.vue' /* webpackChunkName: "pages/index/my-classes/_classId/lectures/_lectureId/index" */))
const _c59c37c6 = () => interopDefault(import('../pages/index/my-classes/_classId/lectures/_lectureId/assignment/result.vue' /* webpackChunkName: "pages/index/my-classes/_classId/lectures/_lectureId/assignment/result" */))
const _ecc27322 = () => interopDefault(import('../pages/index/my-classes/_classId/lectures/_lectureId/assignment/solve.vue' /* webpackChunkName: "pages/index/my-classes/_classId/lectures/_lectureId/assignment/solve" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _c326f1ce,
    name: "admin",
    children: [{
      path: "classes",
      component: _0fe28a33,
      name: "admin-index-classes"
    }, {
      path: "comments",
      component: _727b4fdd,
      name: "admin-index-comments"
    }, {
      path: "lectures",
      component: _3ec6d75e,
      name: "admin-index-lectures"
    }, {
      path: "level",
      component: _5a56c491,
      name: "admin-index-level"
    }, {
      path: "purchase-history",
      component: _0f1ac6b1,
      name: "admin-index-purchase-history"
    }, {
      path: "resources",
      component: _5b69ee1c,
      name: "admin-index-resources"
    }, {
      path: "users",
      component: _7cc16215,
      name: "admin-index-users"
    }, {
      path: "users-admin",
      component: _01292b57,
      name: "admin-index-users-admin"
    }, {
      path: "voice-test",
      component: _0faa6114,
      name: "admin-index-voice-test"
    }, {
      path: "classes/create",
      component: _142306eb,
      name: "admin-index-classes-create"
    }, {
      path: "lectures/create",
      component: _7c6f41c0,
      name: "admin-index-lectures-create"
    }, {
      path: "resources/create",
      component: _c26b3fe8,
      name: "admin-index-resources-create"
    }, {
      path: "classes/:classId?",
      component: _16fd4ba3,
      name: "admin-index-classes-classId"
    }, {
      path: "lectures/:lectureId",
      component: _5b4a9ae1,
      name: "admin-index-lectures-lectureId"
    }, {
      path: "resources/:resourceId?",
      component: _af8212f0,
      name: "admin-index-resources-resourceId"
    }, {
      path: "lectures/:lectureId?/lessons/create",
      component: _06c14751,
      name: "admin-index-lectures-lectureId-lessons-create"
    }, {
      path: "lectures/:lectureId?/lessons/:lessonId?",
      component: _7d7926e6,
      name: "admin-index-lectures-lectureId-lessons-lessonId"
    }]
  }, {
    path: "/admin/login",
    component: _6477669a,
    name: "admin-login"
  }, {
    path: "/admin/sample",
    component: _5652ce94,
    name: "admin-sample"
  }, {
    path: "/",
    component: _61eb55ce,
    children: [{
      path: "",
      component: _e1023648,
      name: "index"
    }, {
      path: "contact",
      component: _5f7ad8a6,
      name: "index-contact"
    }, {
      path: "login",
      component: _183c0c36,
      name: "index-login"
    }, {
      path: "my-classes",
      component: _3f28842e,
      name: "index-my-classes"
    }, {
      path: "my-page",
      component: _586a34c6,
      name: "index-my-page"
    }, {
      path: "pricing",
      component: _05b237d3,
      name: "index-pricing"
    }, {
      path: "resources",
      component: _4ce6521c,
      name: "index-resources"
    }, {
      path: "signup",
      component: _2b0bd381,
      name: "index-signup"
    }, {
      path: "my-classes/:classId",
      component: _7c615c4b,
      name: "index-my-classes-classId"
    }, {
      path: "resources/:resourceId",
      component: _832376f0,
      name: "index-resources-resourceId"
    }, {
      path: "statistics/:classId?",
      component: _c9c80f2c,
      name: "index-statistics-classId"
    }, {
      path: "video-assignment/:id?",
      component: _52a9c3f0,
      name: "index-video-assignment-id"
    }, {
      path: "my-classes/:classId?/lectures",
      component: _01debecf,
      name: "index-my-classes-classId-lectures"
    }, {
      path: "speak-alone/:lectureId?/result",
      component: _483907ed,
      name: "index-speak-alone-lectureId-result"
    }, {
      path: "speak-alone/:lectureId?/solve",
      component: _1b882ec2,
      name: "index-speak-alone-lectureId-solve"
    }, {
      path: "speak-test/:lectureId?/result",
      component: _cfe803d4,
      name: "index-speak-test-lectureId-result"
    }, {
      path: "speak-test/:lectureId?/solve",
      component: _26e5ed54,
      name: "index-speak-test-lectureId-solve"
    }, {
      path: "my-classes/:classId?/lectures/:lectureId",
      component: _457652d0,
      name: "index-my-classes-classId-lectures-lectureId"
    }, {
      path: "my-classes/:classId?/lectures/:lectureId/assignment/result",
      component: _c59c37c6,
      name: "index-my-classes-classId-lectures-lectureId-assignment-result"
    }, {
      path: "my-classes/:classId?/lectures/:lectureId/assignment/solve",
      component: _ecc27322,
      name: "index-my-classes-classId-lectures-lectureId-assignment-solve"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
